#carouselExampleCaptions {
  width: 100%;
  overflow: hidden;
}

.carousel-item img {
  width: 100%;
  height: auto;
  max-height: 500px;
  object-fit: contain;
}

.carousel-control-prev,
.carousel-control-next {
  filter: invert(100%);
}

.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden;
}

.carousel-item {
  display: none;
  position: relative;
  width: 100%;
  transition: transform 0.6s ease;
}

.carousel-item.active {
  display: block;
  text-align: center;
}

.carousel-caption-top {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 80%;
  max-width: 500px;
  padding: 1rem;
  background: rgba(0, 0, 0, 0.5);
  color: white;
  text-align: center;
  border-radius: 10px;
  box-sizing: border-box;
}

.carousel-caption-top-content {
  display: inline-block;
  max-width: 100%;
}

.carousel-indicators button {
  background-color: #000;
}

.carousel-indicators .active {
  background-color: #fff;
}

@media only screen and (max-width: 768px) {
  .carousel-caption-top {
    font-size: 14px;
  }
}
