@import url("https://fonts.googleapis.com/css2?family=Aref+Ruqaa+Ink:wght@400;700&display=swap");

.button-container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 20px;
  padding-bottom: 20px;
}

.book-now-btn {
  background-color: #000;
  color: #fff;
  border: none;
  border-radius: 4px;
  padding: 10px 20px;
  font-size: 36px;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.3s ease;
  align-items: center;
  letter-spacing: 5px;
  font-family: "Aref Ruqaa Ink", serif;
  font-weight: 700;
  font-style: bold;
  text-decoration: none; /* Remove underline */
}

.book-now-btn:hover {
  background-color: pink;
}


.book-now-btn:hover, .book-now-btn.active {
  color: white; /* Change color when hovered over or clicked */
  text-decoration: none; /* Remove underline when hovered over or clicked */
}
