@import url("https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,100..900;1,100..900&display=swap");

.rating-body {
  text-align: center;
  font-family: "Raleway", sans-serif;
  font-size: 30px;
}

.stars {
  color: pink;
  font-size: 30px;
  margin: 0 2px;
}

.bottom-rating {
  font-size: 25px;
  font-style: italic;
  text-align: center;
  padding-right: 20px;
  padding-left: 20px;
}

/* Responsive styles */
@media only screen and (max-width: 768px) {
  .rating-body {
    font-size: 24px; /* Further reduce font size for smaller screens if necessary */
  }

  .stars {
    font-size: 24px; /* Further reduce font size for smaller screens if necessary */
  }

  .bottom-rating {
    font-size: 16px; /* Further reduce font size for smaller screens if necessary */
  }
}
