@import url("https://fonts.googleapis.com/css2?family=Aref+Ruqaa+Ink:wght@400;700&display=swap");

.form-container {
  max-width: 400px;
  margin: 0 auto;
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.form-container label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
  font-family: "Aref Ruqaa Ink", serif;
  font-weight: 700;
  font-style: bold;
}

.form-container input,
.form-container textarea {
  width: 100%;
  padding: 10px;
  margin-bottom: 10px;
  border-radius: 4px;
  box-sizing: border-box;
}

.form-container button {
  width: 100%;
  padding: 10px;
  background-color: #000000;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  font-family: "Aref Ruqaa Ink", serif;
  font-weight: 700;
  font-style: bold;
}

.form-container button:hover {
  background-color: pink;
}

.validation-error {
  color: red;
  font-size: 12px;
  margin-top: 5px;
}

.return-message {
  color: rgb(0, 0, 0);
  padding: 20px;
  margin-top: 20px;
  border-radius: 8px;
  text-align: center;
  font-size: 35px;
  font-family: "Aref Ruqaa Ink", serif;
  font-weight: 700;
  font-style: bold;
}

.question {
  text-align: center;
}

.email-link {
  text-decoration: none;
  color: inherit;
}
