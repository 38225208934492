@import url("https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,100..900;1,100..900&display=swap");

.container {
  padding: 20px;
}

.card {
  border: none;
  border-radius: 10px;
  transition:
    transform 0.2s ease-in-out,
    box-shadow 0.2s ease-in-out;
}

.card:hover {
  transform: scale(1.05);
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2);
}

.card-body-services {
  padding: 20px;
  text-align: center;
}

.card-title {
  font-size: 1.25rem;
  font-weight: bold;
  color: #333;
  font-family: "Ubuntu Sans", sans-serif;
  font-optical-sizing: auto;
  font-style: normal;
  font-variation-settings: "wdth" 100;
  text-align: center;
}

.card-text {
  font-size: 1rem;
  color: #555;
}

.mx-auto {
  margin-left: auto !important;
  margin-right: auto !important;
}

.card {
  background-color: #f8f9fa;
}

.card-title {
  color: #000000;
}

.card-text {
  line-height: 1.5;
}
