@import url("https://fonts.googleapis.com/css2?family=Aref+Ruqaa+Ink:wght@400;700&display=swap");

.navbar {
  background-color: #565656;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  font-family: "Aref Ruqaa Ink", serif;
  font-weight: 700;
  font-style: bold;
}

.navbar-brand {
  font-size: 1.5rem;
  font-weight: bold;
  color: #333;
}

.navbar-nav {
  margin-left: auto;
}

.nav-item {
  margin-right: 15px;
}

.nav-link {
  color: #333;
  font-weight: bold;
  transition: color 0.3s;
}

.nav-link:hover {
  color: pink;
}

.navbar-toggler-icon {
  border-color: #333;
}

.navbar-toggler {
  border-color: #333;
}

.navbar-brand.nav-link {
  margin-left: 16.6px;
}

@media only screen and (max-width: 768px) {
  .navbar-nav {
    flex-direction: column;
    align-items: center;
    margin-left: 0;
  }

  .nav-item {
    margin: 10px 0;
  }

  .navbar-collapse {
    margin-top: 20px;
  }
}
