body {
  font-family: Arial, sans-serif;
  margin: 0;
  padding: 0;
}

@media only screen and (max-width: 768px) {
  body {
    font-size: 14px;
  }

  img {
    max-width: 100%;
    height: auto;
  }
}
